<script>
import { onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import { useCompetitions } from '/~/extensions/competitions/composables'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import CompetitionsItems from './components/competitions-items.vue'
import CompetitionsFilter from './components/filter/competitions-filter.vue'

export default {
  name: 'competitions-catalog',
  components: {
    CompetitionsFilter,
    CompetitionsItems,
  },
  setup() {
    const route = useRoute()
    const { syncState, routeState, getContent } = useCompetitions()
    const { page } = useEditablePage()

    watch(routeState, (value) => {
      emitter.emit('router:replace', { name: 'competitions', ...value })
    })

    watch(
      () => ({ ...route }),
      (to, from) => {
        if (
          to.fullPath.replace(to.hash, '') !==
          from.fullPath.replace(from.hash, '')
        ) {
          syncState({ to })
        }
      }
    )

    onBeforeMount(() => {
      syncState({ to: route })
      getContent()
    })

    return {
      page,
      ui,
    }
  },
}
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <competitions-filter v-if="ui.mobile" />
    <div v-else class="px-5 pb-3 pt-2">
      <competitions-filter />
    </div>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <div class="p-2.5 sm:p-5">
      <competitions-items class="mt-0 sm:mt-5" />
    </div>
  </div>
</template>
