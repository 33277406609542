<script lang="ts">
import ui from '/~/core/ui'

export default {
  name: 'competitions-filter',
  components: {
    DeskFilter: () => import('./competitions-filter.desk.vue'),
    MobileFilter: () => import('./competitions-filter.mobile.vue'),
  },
  setup() {
    return {
      ui,
    }
  },
}
</script>

<template>
  <mobile-filter v-if="ui.mobile" />
  <desk-filter v-else />
</template>
